<template>
  <div class="md-content md-table-content double-row-table-header">
    <md-table
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
      class="globe-activity md-table-global-sales packages responsive"
    >
      <!-- md-fixed-header -->
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Globe/Date/ID"
          >{{ item.kiosk_id }}<br />
          <small @click="goto(item.packageID)"
            ><b
              ><span>{{
                item.screenDate.slice(0, 10)
                  | moment(
                    "timezone",
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                    "L "
                  )
              }}</span></b
            ><br />{{ item.packageID.slice(0, 16) }}</small
          ></md-table-cell
        >

        <md-table-cell md-label="Member"
          ><small @click="goto(item.packageID)"
            ><span
              style="
                font-weight: 500;
                font-size: 0.85rem;
                color: black;
                cursor: pointer;
              "
              >{{ item.name }}</span
            >
            <br />{{ item.email }}</small
          ></md-table-cell
        >

        <md-table-cell md-label="Created Screening?">
          <div @click="goto(item.packageID)">
            {{ item.created_screening }}
          </div></md-table-cell
        >
        <md-table-cell md-label="Created Member?">
          <div
            @click="goto(item.packageID)"
            v-if="item.created_patient === 'BYPASS-Existing Member'"
          >
            {{ item.created_patient.substring(7, 22) }}
          </div>
          <div @click="goto(item.packageID)" v-else>
            {{ item.created_patient }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Uploaded Assets?">
          <div @click="goto(item.packageID)">
            {{ item.created_blob }}
          </div></md-table-cell
        >
        <md-table-cell md-label="Status/Finalized">
          <div @click="goto(item.packageID)">
            {{ item.package_status }}<br />
            {{ item.finalize_success }}
          </div></md-table-cell
        >
        <md-table-cell md-label="Step Exception"
          ><div @click="goto(item.packageID)">{{ item.process_step }}</div>
          <br />
          <div v-if="item.exception == 'SUCCESS'">
            <span style="font-weight: 500; color: green">
              {{ item.exception }}
            </span>
          </div>
          <div v-else>
            <span style="font-weight: 500; color: red">
              {{ item.exception.substring(0, 27)
              }}<span
                v-if="item.exception.length > 27"
                @click="handleSelect(item.exception)"
                style="cursor: pointer"
              >
                (...)</span
              >
            </span>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="showDialog">
      <div class="modal-size-medium">
        <div class="modal-header">Error Message</div>
        <a class="cancel-profile" @click="cancel()">X</a>
        <div class="d-flex">
          <div class="modal-content scroll p-0 w-100">
            <div class="md-layout">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "PackageTable",
  inject: [
    "listInput",
    "list",
    "showError",
    "showSuccess",
    "convertUTCDateToLocalDate",
  ],
  data() {
    return {
      showDialog: false,
      error: "",
    };
  },
  methods: {
    goto(item) {
      // this.$router.push("/globe-chek/Screenings/Evaluation/" + item);
      this.$router.push({
        name: "ScreeningsEval",
        params: { id: item, from: "packages" },
      });
    },
    handleSelect(item) {
      this.showDialog = true;
      this.error = item;
    },
    cancel() {
      this.showDialog = false;
    },
    // convertDateFormat(tmp) {
    //   debugger;
    //   var date_time = new Date(tmp),
    //     mnth = ("0" + (date_time.getMonth() + 1)).slice(-2),
    //     day = ("0" + date_time.getDate()).slice(-2);

    //   return [mnth, day, date_time.getFullYear()].join("/");
    // },
  },
};
</script>

<style scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.cancel-profile {
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid grey;
  padding: 3px 8px;
  color: black;
  border-radius: 5px;
  cursor: pointer;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.packages.responsive td:nth-of-type(1):before {
  content: "Globe/Date/ID";
}
.packages.responsive td:nth-of-type(2):before {
  content: "Member";
}
.packages.responsive td:nth-of-type(3):before {
  content: "Created Screening?";
}
.packages.responsive td:nth-of-type(4):before {
  content: "Created Member?";
}
.packages.responsive td:nth-of-type(5):before {
  content: "Uploaded Assets?";
}
.packages.responsive td:nth-of-type(6):before {
  content: "Status/Finalized";
}
.packages.responsive td:nth-of-type(7):before {
  content: "Step Exception";
}
</style>
