<template>
  <div>
    <HasPermission :permission="Permissions.PackagesRead">
      <div>
        <list-layout>
          <template v-slot:content>
            <package-table />
          </template>
        </list-layout>
      </div>
    </HasPermission>
  </div>
</template>

<script>
import ListLayout from "../../components/layouts/list-layout/ListLayout.vue";
import PackageTable from "../../components/Globechek/Tables/PackageTable.vue";
import HasPermission from "../../components/HasPermission.vue";
export default {
  inject: ["Permissions"],
  name: "Package",
  components: {
    ListLayout,
    PackageTable,
    HasPermission,
  },
};
</script>

<style></style>
